.csv-download-button {
	background-color: #ecf7fa;
	/* font-size: 0.8rem; */
	/* color: #fff; */
	border: 0;
	padding: 2;
	margin-left: 5px;
}

.csv-download-button:hover {
	cursor: pointer;
}
