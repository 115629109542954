#videoElement {
	position: 'relative';
	width: '300px';
	height: 'auto';
	border-radius: '22px';
	border: '4px solid #cccccc';
	overflow: 'hidden';
	/* -webkit-transform: "translateZ(0)"; */
	box-shadow: '0 19px 51px 0 rgba(0,0,0,0.16), 0 14px 19px 0 rgba(0,0,0,0.07)';
}

#container {
	position: 'absolute';
	left: '10vh';
	bottom: '50px';
}
