/* * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
} */

@font-face {
	font-family: 'quicksand';
	src: url('./NotoSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* body {
	font-family: 'quicksand';
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
} */

.dot {
	width: 5px; /* Adjust size as needed */
	height: 5px; /* Adjust size as needed */
	background-color: #000; /* Color of the dot */
	margin-top: 100px;
	border-radius: 50%; /* Ensures the dot is circular */
}

.open-sans-header {
	font-family: 'Open Sans', sans-serif;
	font-optical-sizing: auto;
}
.editable-paragraph {
	width: 100%;
	border: none;

	font-family: 'robotoslab';
	font-size: 18px;
	line-height: 1.5;
	background: none;
	resize: none;
	overflow: hidden;
	outline: none;
	box-shadow: none;
}

/* src/SplitLayout.css */
.split-layout {
	display: flex;
	height: 100vh;
}

.left-side {
	background-color: white;
	padding: 20px;
	overflow-y: auto;

	height: 100vh; /* Ensure it takes the full viewport height */
	scrollbar-width: thin; /* For Firefox */
	scrollbar-color: auto; /* For Firefox */

	/* For WebKit browsers */
	&::-webkit-scrollbar {
		width: 10px; /* Different width */
	}

	&::-webkit-scrollbar-track {
		background: #f0f0f0; /* Different background */
	}

	&::-webkit-scrollbar-thumb {
		background: #888; /* Different handle color */
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #555; /* Different handle hover color */
	}
}

.right-side {
	padding: 20px;
	height: 100vh; /* Ensure it takes the full viewport height */
	overflow-y: auto;

	background-color: #e8e8e8;
}

.resizer {
	width: 5px;
	cursor: col-resize;
	background-color: rgb(203, 202, 202);
	position: relative;
	z-index: 1;
}

/* HTML: <div class="loader"></div> */
.loader {
	width: 50px;
	--b: 8px;
	aspect-ratio: 1;
	border-radius: 50%;
	padding: 1px;
	background: conic-gradient(#0000 10%, #183ae3) content-box;
	-webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
		radial-gradient(
			farthest-side,
			#0000 calc(100% - var(--b) - 1px),
			#000 calc(100% - var(--b))
		);
	-webkit-mask-composite: destination-in;
	mask-composite: intersect;
	animation: l4 1s infinite steps(10);
}
@keyframes l4 {
	to {
		transform: rotate(1turn);
	}
}
