.quizContainerDisplay {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 60%;
	margin: 0 auto;
	.quizQuestion {
		margin: 20px 0 20px 0;
		justify-content: center;
	}
	.checkAnswerBtn {
		justify-content: center;
		justify-self: center;
		margin: 20px auto;
		width: 100%;
	}
	.quizOptions {
		margin: 20px 0 0 0;
	}
	.span {
		margin-left: 10px;
	}
}

.quizMobileStepper {
	width: 80%;
}

.alert-enter {
	opacity: 0;
	transform: scale(0.9);
}
.alert-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition:
		opacity 300ms,
		transform 300ms;
}
.alert-exit {
	opacity: 1;
}
.alert-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition:
		opacity 300ms,
		transform 300ms;
}

.imgDiv {
	justify-content: center;
	margin: 0 auto !important;
	flex-direction: column;
}

@media screen and (max-width: 500px) {
	.quizMobileStepper {
		width: auto;
	}
}
