.mic_holder {
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	z-index: 99999;
}

.audio_recorder_div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	background-color: #949291;
	opacity: 0.8;
	border-radius: 50%;
	margin-right: 20px;
	z-index: 99999;
	/* border-radius: 16px; */
	/* border-top-left-radius: 16px;
    border-bottom-left-radius: 16px; */
	/* background-color: rgba(0, 0, 0, 0.5); */
	&:hover {
		background-color: rgba(0, 0, 0, 0.8);
	}
}

.tooltip_mic {
	/* position: absolute; */
	/* bottom: 150px; */
	padding: 20px;
	width: 150px;
	/* left:50%; */
	/* transform: translateX(-100px); */
	text-align: center;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.304);
	z-index: 99999;
}

.arrow {
	/* position: absolute; */
	/* top: 80px; */
	/* left: 50%; */
	/* transform: translateX(-50%); */
}
.icon {
	font-size: 50px;
	font-weight: 600;
	color: #ffffff;
	animation: arrowAnimation 1s infinite;
	z-index: 99999;
}
@keyframes arrowAnimation {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(15px);
	}
	100% {
		transform: translateY(0px);
	}
}

@media screen and (max-width: 500px) {
	/* .mic_holder {
        bottom: 100px;
    } */
	.tooltip_mic {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 75px;
		height: 80px;
		font-size: 10px;
		padding: 10px;
		z-index: 99999;
	}
	.audio_recorder_div {
		width: 100px;
		z-index: 99999;
	}
}
