.report-container {
	background-color: #fff;
	max-width: 800px;
	margin: auto;
}

.report-details {
	background-color: #004aad;
	padding: 0 3rem;
	display: flex;
	justify-content: space-between;
	color: #413f3f;
	font-size: 0.9rem;
	font-weight: 500;
	margin-bottom: 1rem;
}

.report-details-name-value {
	color: #ffff;
	font-weight: bold;
	font-size: 2rem;
	margin-top: 1rem;
	font-family: Gordita, Arial, sans-serif;
}

.report-details-subDetails-value {
	color: #ffff;
	font-weight: 500;
	font-size: 1.6rem;
	margin-top: 0rem;
	margin-bottom: 1rem;
	font-family: Gordita, Arial, sans-serif;
}

.report-details-date-value {
	color: #ffff;
	font-weight: medium;
	font-size: 1.4rem;
	margin-top: 1rem;
	font-family: Gordita, Arial, sans-serif;
}

.report-details-container {
	/* padding: 1rem; */
}

.performance-summary {
	/* width: 100%; */
	height: 50px;
	background-color: #1976d2;
}

.question {
	font-family: Gordita, Arial, sans-serif;

	font-weight: 400;
	color: #000000;
	font-size: 0.9rem;
	margin-bottom: 1rem;
}

.user-response-heading {
	color: #355389;
	font-weight: 500;
}

.user-response-details {
	color: #355389;
	font-weight: 400;
	font-size: 0.9rem;
}

.feedback-details {
	color: #355389;
	font-weight: 400;
	font-size: 0.9rem;
}

.feedback-heading {
	color: #355389;
	font-weight: 500;
}

.scores-container {
	width: 100%;
	margin-top: 1rem;
	margin-bottom: 2rem;
}

.scorename-heading {
	color: #355389;
	font-weight: 500;
}

.scores-container-row {
	display: flex;
	justify-content: space-around;
	width: 100%;
	padding: 0.2rem;
	margin-top: 0.2rem;
	margin-bottom: 2rem;
}

.scores-container-col {
	display: flex;
	justify-content: space-between;
}

.progress {
	width: 100px;
	height: 100px;
	background: none;
	position: relative;
}

.progress::after {
	content: '';
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 10px solid #eee;
	position: absolute;
	top: 0;
	left: 0;
}

.progress > span {
	width: 50%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	z-index: 1;
}

.progress .progress-left {
	left: 0;
}

.progress .progress-bar {
	width: 100%;
	height: 100%;
	background: none;
	border-width: 10px;
	border-style: solid;
	position: absolute;
	top: 0;
}

.progress .progress-left .progress-bar {
	left: 100%;
	border-top-right-radius: 100px;
	border-bottom-right-radius: 100px;
	border-left: 0;
	-webkit-transform-origin: center left;
	transform-origin: center left;
}

.progress .progress-right {
	right: 0;
}

.progress .progress-right .progress-bar {
	left: -100%;
	border-top-left-radius: 100px;
	border-bottom-left-radius: 100px;
	border-right: 0;
	-webkit-transform-origin: center right;
	transform-origin: center right;
}

.progress .progress-value {
	position: absolute;
	top: 0;
	left: 0;
}

.border-primary {
	border-color: #004aad !important;
}

.score-heading {
	color: #355389;
	font-size: 0.8rem;
	text-align: center;
}

.report-summary-heading {
	background-color: #004aad;
	padding: 0 1rem;
	justify-content: space-between;
	color: #413f3f;
	font-size: 0.9rem;
	font-weight: 500;
	margin-bottom: 1rem;
}

.report-summary-title {
	color: #ffff;
	font-weight: 500;
	font-size: 1.6rem;
	margin-top: 0rem;
	margin-bottom: 1rem;
	font-family: Gordita, Arial, sans-serif;
}

.recomendation {
	color: #355389;
	font-weight: 500;
	margin-top: 3rem;
	display: flex;
	align-items: center;
}

.recomendation button {
	margin-left: 1rem;
	padding: 0.5rem 1rem;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.report-footer {
	color: #355389;
	padding: 0.5rem;
	width: 100%;
	font-size: 0.7rem;
}

sup {
	top: 0;
}

/* @media only screen and (max-width: 550px) {
    html {
        font-size: 12px;
    }
} */

@media only screen and (max-width: 440px) {
	.progress {
		width: 70px;
		height: 70px;
	}

	.progress::after {
		border: 5px solid #eee;
	}

	.progress .progress-bar {
		border-width: 5px;
	}
}
