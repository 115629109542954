.swiper-button-prev,
.swiper-button-next {
	bottom: 20px; /* Adjust this value to position the arrows */
	width: 44px;
	height: 44px;
	background-size: 44px 44px;
	background-repeat: no-repeat;
	margin-top: 0;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
}

.swiper-button-next:after {
  border-width: 8px 0 8px 12px;
  border-color: transparent transparent transparent white;
}

.swiper-button-prev:after {
  border-width: 8px 12px 8px 0;
  border-color: transparent white transparent transparent;
}
