.loginContainer {
	/* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
	width: 500px;
	height: auto;
	border-radius: 16px;
	align-items: center;
	text-align: center;
	padding: 20px;
	border: 1px solid #e5e4e2;
}

.switchLoginLink {
	text-decoration: underline;
	color: #224c84;
	cursor: pointer;
}

.loginPageContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	height: calc(100% - 64px);
	width: 100vw;
	margin-top: 50px;
}

@media screen and (max-width: 530px) {
	.loginContainer {
		width: 400px;
	}
}

@media screen and (max-width: 420px) {
	.loginContainer {
		width: 300px;
	}
}
