body {
	background-color: #ecf7fa;
	height: 100vh;
}
.App {
	background-color: #ecf7fa;
	height: 100vh;
}

html {
	background-color: #ecf7fa;
}

.MuiLoadingButton-loadingIndicator {
	transform: translateX(5px) translateY(5px) !important;
}
