.results-container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.report-container {
	background-color: #fff;
	max-width: 800px;
	margin: auto;
}

.report-details {
	background-color: #1976d2;
	padding: 0 1rem;
	display: flex;
	justify-content: space-between;
	color: #413f3f;
	font-size: 0.9rem;
	font-weight: 500;
	margin-bottom: 1rem;
}

.report-details-name-value {
	color: #ffff;
	font-weight: bold;
	font-size: 2rem;
	margin-top: 1rem;
	font-family: Gordita, Arial, sans-serif;
}

.report-details-subDetails-value {
	color: #ffff;
	font-weight: 500;
	font-size: 1.6rem;
	margin-top: 0rem;
	margin-bottom: 1rem;
	font-family: Gordita, Arial, sans-serif;
}

.report-details-date-value {
	color: #ffff;
	font-weight: medium;
	font-size: 1.4rem;
	margin-top: 1rem;
	font-family: Gordita, Arial, sans-serif;
}

.performance-summary {
	width: 96%;
	height: auto;
	background-color: #1976d2;
	display: table;
	padding: 0.5rem 1rem;
}

.performance-summary > h4 {
	display: table-cell;
	vertical-align: middle;
	color: #ffffff;
}

.performance-legend {
	display: flex;
	flex-direction: column;
}

.performance-legend > .legendItem {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.performance-legend > .legendItem > div {
	width: 50px;
	height: 20px;
	border-radius: 28px;
	margin-right: 15px;
}

.success {
	background-color: #66bb6a;
}

.warning {
	background-color: #c0eb34;
}
.fair {
	background-color: #ffd500;
}

.error {
	background-color: #ff0000;
}

.highly {
	background-color: #148f5d;
}
.moderately {
	background-color: #02c677;
}

.intermediate {
	background-color: #ffd500;
}

.evaluation-container {
	display: flex;
	flex-direction: column;
	padding: 30px;
}

.evaluation-score {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
}

.question-details-container {
	padding: 0px 30px;
}

.heading {
	font-weight: 700;
	color: #1976d2;
	line-height: 0;
}

.subHeading {
	line-height: 0;
	font-size: 14px;
	padding-top: 10px;
	color: #1976d2;
}

.rating {
	font-size: 14px;
	font-weight: 700;
	margin-right: 5px;
}

.question-details {
	padding-bottom: 20px;
	padding-top: 20px;
}

.question {
	font-family: Gordita, Arial, sans-serif;
	font-weight: 400;
	color: #000000;
	font-size: 0.9rem;
	margin-bottom: 1rem;
}

.user-response-heading {
	color: #355389;
	font-weight: 500;
}

.user-response-details {
	color: #355389;
	font-weight: 400;
	font-size: 0.9rem;
}

.feedback-details {
	color: #355389;
	font-weight: 400;
	font-size: 0.9rem;
}

.feedback-heading {
	color: #355389;
	font-weight: 500;
}

.scores-container {
	width: 100%;
	margin-top: 1rem;
	margin-bottom: 2rem;
}

.scorename-heading {
	color: #355389;
	font-weight: 500;
}

.scores-container-row {
	display: flex;
	justify-content: space-around;
	width: 100%;
	padding: 0.2rem;
	margin-top: 0.2rem;
	margin-bottom: 2rem;
}

.scores-container-col {
	display: flex;
	justify-content: space-between;
}
