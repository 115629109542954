.img1 {
	width: 150px;
}

.img2 {
	width: 190px;
}
.img3 {
	width: 175px;
}
.hiring-interview {
	width: 300px;
}
.exit-interview {
	width: 300px;
}
.example-bots {
	width: 600px;
}
@media only screen and (max-width: 600px) {
	.img1 {
		width: 90px;
	}

	.img2 {
		width: 130px;
	}
	.img3 {
		width: 100px;
		margin-top: 50px;
	}
	.hiring-interview {
		width: 200px;
	}
	.exit-interview {
		width: 200px;
	}
	.example-bots {
		width: 300px;
	}
}
.container {
	display: flex;
	/* width: 100%; */
	/* height: 100vh; */
	justify-content: center;
	align-items: center;
}

.bg {
	height: 50px;
	width: 300px;
	border-radius: 10px;
	position: absolute;
}
.bg {
	animation: pulse 1.2s ease infinite;
	background: #ffc107;
}
@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		opacity: 0.3;
	}
	100% {
		transform: scale(1.1, 1.4);
		opacity: 0;
	}
}

@keyframes bounce {
	0% {
		top: 0;
	}
	50% {
		top: -0.2em;
	}
	70% {
		top: -0.3em;
	}
	100% {
		top: 0;
	}
}

.down-arrow {
	animation: bounce 1s infinite linear;
}
