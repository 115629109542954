.bugReportCTA {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #ffc107;
	text-align: center;
	display: flex;
	justify-content: center;
	padding: 10px;
	cursor: pointer;
	margin: 10px 10px 10px 20px;
	&:hover {
		opacity: 0.8;
	}
}
.bugReportIcon {
	color: #224c84;
	width: 30px !important;
	height: 30px !important;
	&:hover {
		color: #ecf7fa;
	}
}
