.meetingEndHeader {
	font-size: 2rem;
	/* margin-bottom: 1.3rem; */
	color: #ffffff;
}

.feedbackSection {
	/* margin-top: 40px; */
	background: #ffffff;
	padding: 50px;
}

.ratingHeader {
	text-align: left;
	font-size: 22px;
	margin: 0px;
	margin-bottom: 20px;
}

.ratingContainer {
	display: flex;
	gap: 30px;
	margin-bottom: 30px;
}

.ratingOption {
	border: 1px solid #1976d2;
	padding: 10px 20px;
	border-radius: 25px;
	font-size: 15px !important;
	cursor: pointer;
	color: #1976d2;
	background-color: white;
}

.feedbackArea {
	width: auto;
	height: 90px;
	font-size: 16px;
	padding: 5px;
	margin-bottom: 20px;
	border-radius: 10px;
	border-color: gray;
}

@media screen and (max-width: 570px) {
	.feedbackContainer {
		width: 400px;
	}
	.feedbackHeader {
		font-size: 1.5rem;
	}
	.feedbackSecondaryHeader {
		font-size: 1.4rem;
	}
}

@media screen and (max-width: 480px) {
	.feedbackContainer {
		width: 300px;
	}
	.feedbackHeader {
		font-size: 1.3rem;
	}
	.feedbackSecondaryHeader {
		font-size: 1.1rem;
	}
}
