.button {
	background-color: transparent;
	border: 1px solid #b62626;
	box-sizing: border-box;
	color: #00132c;
	font-family: 'Avenir Next LT W01 Bold', sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	padding: 16px 23px;
	position: relative;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

.button:hover,
.button:active {
	outline: 0;
}

.button:hover {
	background-color: transparent;
	cursor: pointer;
}

.button:before {
	background-color: #d3b384;
	content: '';
	height: calc(100% + 3px);
	position: absolute;
	right: -7px;
	top: -9px;
	transition: background-color 300ms ease-in;
	width: 100%;
	z-index: -1;
}

.button:hover:before {
	background-color: #6dcff6;
}

@media (min-width: 768px) {
	.button {
		padding: 16px 32px;
	}
}
