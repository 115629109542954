@font-face {
	font-family: 'Gordita';
	src: url('../public/fonts/Gordita-Regular.woff') format('truetype');
	font-weight: normal;
	font-style: normal;
}

body {
	font-family: 'Gordita', sans-serif;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #224c84;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	cursor: pointer;
}
